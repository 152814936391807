var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',_vm._g(_vm._b({attrs:{"transition":"scale-transition","width":1280},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-card',{staticClass:"elevation-3 d-flex flex-column rounded-lg"},[_c('v-card-title',[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t('messages.views.admin.components.core.defectsModal.title'))+" ")]),_c('v-spacer'),_c('span',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.model = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-card-text',{staticClass:"flex-grow-0"},[_c('o-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('o-select',{attrs:{"items":[0, 1, 2],"label":"labels.defect.seriousness","hide-details":"","clearable":"","prepend-icon":"mdi-alert-octagon"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"cursor-pointer",attrs:{"color":_vm.getSeriousnessColor(item),"small":""}},[_vm._v(" "+_vm._s(_vm.$t('constants.defect.seriousness.'+item))+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"cursor-pointer",attrs:{"color":_vm.getSeriousnessColor(item),"small":""}},[_vm._v(" "+_vm._s(_vm.$t('constants.defect.seriousness.'+item))+" ")])]}}]),model:{value:(_vm.search.seriousness),callback:function ($$v) {_vm.$set(_vm.search, "seriousness", $$v)},expression:"search.seriousness"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('o-select',{attrs:{"items":_vm.types,"label":"labels.defect.type","hide-details":"","clearable":"","prepend-icon":"mdi-label"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item + ' : ' + _vm.$t('constants.defect.description.' + item))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item + ' : ' + _vm.$t('constants.defect.description.' + item))+" ")]}}]),model:{value:(_vm.search.type),callback:function ($$v) {_vm.$set(_vm.search, "type", $$v)},expression:"search.type"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('o-text-field',{attrs:{"rules":_vm.validation.parameter,"label":_vm.searchParameterLabel,"disabled":_vm.isSearchParameterDisabled,"hide-details":"","disable-translation":"","prepend-icon":"mdi-vector-link"},model:{value:(_vm.search.parameter),callback:function ($$v) {_vm.$set(_vm.search, "parameter", $$v)},expression:"search.parameter"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('o-select',{attrs:{"items":_vm.stations,"label":"labels.defect.station","hide-details":"","clearable":"","prepend-icon":"mdi-server","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStationName(item))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStationName(item))+" ")]}}]),model:{value:(_vm.search.station),callback:function ($$v) {_vm.$set(_vm.search, "station", $$v)},expression:"search.station"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('o-date-field',{attrs:{"rules":_vm.validation.from,"label":"messages.views.admin.components.core.defectsModal.from","hide-details":"","prepend-icon":"mdi-calendar","clearable":""},model:{value:(_vm.search.from),callback:function ($$v) {_vm.$set(_vm.search, "from", $$v)},expression:"search.from"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('o-date-field',{attrs:{"rules":_vm.validation.to,"label":"messages.views.admin.components.core.defectsModal.to","hide-details":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.search.to),callback:function ($$v) {_vm.$set(_vm.search, "to", $$v)},expression:"search.to"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('o-boolean-select',{attrs:{"clearable":"","label":"messages.views.admin.components.core.defectsModal.resolved","hide-details":"","prepend-icon":"mdi-check"},model:{value:(_vm.search.resolved),callback:function ($$v) {_vm.$set(_vm.search, "resolved", $$v)},expression:"search.resolved"}})],1)],1)],1)],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-card-text',{staticClass:"flex-grow-1"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.defects,"server-items-length":_vm.defectCount,"loading":_vm.loading,"item-class":_vm.getItemClass,"height":352,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"dense":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.seriousness",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getSeriousnessColor(item.seriousness),"small":""}},[_vm._v(" "+_vm._s(_vm.$t('constants.defect.seriousness.' + item.seriousness))+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDefectDescription(item)))]),(item.parameter !== null)?_c('span',[_vm._v(" ("+_vm._s(_vm.getDefectParameterLabel(item) + ' : ' + item.parameter)+") ")]):_vm._e()]}},{key:"item.at",fn:function(ref){
var item = ref.item;
return [(item.at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("localMoment")(item.at,'L'))+" "+_vm._s(_vm._f("localMoment")(item.at,'LTS'))+" ")]):_vm._e()]}},{key:"item.until",fn:function(ref){
var item = ref.item;
return [(item.until)?_c('span',[_vm._v(" "+_vm._s(_vm._f("localMoment")(item.until,'L'))+" "+_vm._s(_vm._f("localMoment")(item.until,'LTS'))+" ")]):_vm._e()]}},{key:"item.station",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStationName(item.station))+" ")]}},{key:"item.resolved",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.until !== null ? 'success' : 'error'},domProps:{"textContent":_vm._s(item.until !== null ? 'mdi-check' : 'mdi-close')}})]}},{key:"footer",fn:function(){return [_c('o-data-footer',{attrs:{"has-previous-page":!!_vm.data && !!_vm.data.searchDefects && (_vm.data.searchDefects.pageInfo.hasPreviousPage || _vm.pagination.after !== null),"has-next-page":!!_vm.data && !!_vm.data.searchDefects && (_vm.data.searchDefects.pageInfo.hasNextPage || _vm.pagination.before !== null)},on:{"previous":_vm.previous,"next":_vm.next}})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }