<template>
  <v-dialog
    v-model="model"
    transition="scale-transition"
    :width="1280"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card
      class="elevation-3 d-flex flex-column rounded-lg"
    >
      <v-card-title>
        <span class="primary--text">
          {{ $t('messages.views.admin.components.core.defectsModal.title') }}
        </span>

        <v-spacer />

        <span>
          <v-btn
            icon
            @click="model = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text
        class="flex-grow-0"
      >
        <o-form>
          <v-row>
            <v-col cols="2">
              <o-select
                v-model="search.seriousness"
                :items="[0, 1, 2]"
                label="labels.defect.seriousness"
                hide-details
                clearable
                prepend-icon="mdi-alert-octagon"
              >
                <template v-slot:item="{ item }">
                  <v-chip
                    :color="getSeriousnessColor(item)"
                    small
                    class="cursor-pointer"
                  >
                    {{ $t('constants.defect.seriousness.'+item) }}
                  </v-chip>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip
                    :color="getSeriousnessColor(item)"
                    small
                    class="cursor-pointer"
                  >
                    {{ $t('constants.defect.seriousness.'+item) }}
                  </v-chip>
                </template>
              </o-select>
            </v-col>
            <v-col cols="4">
              <o-select
                v-model="search.type"
                :items="types"
                label="labels.defect.type"
                hide-details
                clearable
                prepend-icon="mdi-label"
              >
                <template v-slot:item="{ item }">
                  {{ item + ' : ' + $t('constants.defect.description.' + item) }}
                </template>
                <template v-slot:selection="{ item }">
                  {{ item + ' : ' + $t('constants.defect.description.' + item) }}
                </template>
              </o-select>
            </v-col>
            <v-col cols="3">
              <o-text-field
                v-model="search.parameter"
                :rules="validation.parameter"
                :label="searchParameterLabel"
                :disabled="isSearchParameterDisabled"
                hide-details
                disable-translation
                prepend-icon="mdi-vector-link"
              />
            </v-col>
            <v-col cols="3">
              <o-select
                v-model="search.station"
                :items="stations"
                label="labels.defect.station"
                hide-details
                clearable
                prepend-icon="mdi-server"
                item-value="id"
              >
                <template v-slot:item="{ item }">
                  {{ getStationName(item) }}
                </template>
                <template v-slot:selection="{ item }">
                  {{ getStationName(item) }}
                </template>
              </o-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <o-date-field
                v-model="search.from"
                :rules="validation.from"
                label="messages.views.admin.components.core.defectsModal.from"
                hide-details
                prepend-icon="mdi-calendar"
                clearable
              />
            </v-col>
            <v-col cols="4">
              <o-date-field
                v-model="search.to"
                :rules="validation.to"
                label="messages.views.admin.components.core.defectsModal.to"
                hide-details
                prepend-icon="mdi-calendar"
              />
            </v-col>
            <v-col cols="4">
              <o-boolean-select
                v-model="search.resolved"
                clearable
                label="messages.views.admin.components.core.defectsModal.resolved"
                hide-details
                prepend-icon="mdi-check"
              />
            </v-col>
          </v-row>
        </o-form>
      </v-card-text>

      <v-divider class="mb-2" />

      <v-card-text
        class="flex-grow-1"
      >
        <v-data-table
          :headers="headers"
          :items="defects"
          :server-items-length="defectCount"
          :loading="loading"
          :item-class="getItemClass"
          :height="352"
          :page="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          dense
          disable-sort
          hide-default-footer
        >
          <template v-slot:item.seriousness="{ item }">
            <v-chip
              :color="getSeriousnessColor(item.seriousness)"
              small
            >
              {{ $t('constants.defect.seriousness.' + item.seriousness) }}
            </v-chip>
          </template>

          <template v-slot:item.description="{ item }">
            <span>{{ getDefectDescription(item) }}</span>
            <span v-if="item.parameter !== null">
              ({{ getDefectParameterLabel(item) + ' : ' + item.parameter }})
            </span>
          </template>

          <template v-slot:item.at="{ item }">
            <span v-if="item.at">
              {{ item.at|localMoment('L') }} {{ item.at|localMoment('LTS') }}
            </span>
          </template>

          <template v-slot:item.until="{ item }">
            <span v-if="item.until">
              {{ item.until|localMoment('L') }} {{ item.until|localMoment('LTS') }}
            </span>
          </template>

          <template v-slot:item.station="{ item }">
            {{ getStationName(item.station) }}
          </template>

          <template v-slot:item.resolved="{ item }">
            <v-icon
              :color="item.until !== null ? 'success' : 'error'"
              v-text="item.until !== null ? 'mdi-check' : 'mdi-close'"
            />
          </template>

          <template v-slot:footer>
            <o-data-footer
              :has-previous-page="!!data && !!data.searchDefects && (data.searchDefects.pageInfo.hasPreviousPage || pagination.after !== null)"
              :has-next-page="!!data && !!data.searchDefects && (data.searchDefects.pageInfo.hasNextPage || pagination.before !== null)"
              @previous="previous"
              @next="next"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {
    getDefectDescription, getDefectParameterLabelFromType, getDefectParameterType,
    getSeriousness, getTypes
  } from '@utils/defect';
  import moment from 'moment';
  import { mapState } from 'vuex';
  import { getStationName } from '@/utils/station';

  const DEFAULT_ITEMS_PER_PAGE = 10;

  export default {
    props: {
      value: {
        type: Boolean,
        default: null,
      },
    },

    apollo: {
      data: {
        query: require('@gql/views/admin/components/core/defects-modal/getData.gql'),
        client: 'stations',
        variables: function () {
          const variables = {
            first: this.pagination.first,
            last: this.pagination.last,
            before: this.pagination.before,
            after: this.pagination.after,
            seriousness: this.search.seriousness,
            type: this.search.type !== '' ? parseInt(this.search.type) : null,
            parameter: this.search.parameter !== '' ? parseInt(this.search.parameter) : null,
            station: this.search.station !== '' ? this.search.station : null,
            from: this.search.from ? moment(this.search.from).utc().format('Y-MM-DD HH:mm:ss') : null,
            to: this.search.to ? moment(this.search.to).add(1, 'day').utc().format('Y-MM-DD HH:mm:ss') : null,
          };

          if (this.search.resolved !== null && this.search.resolved !== undefined) {
            variables.resolved = !!this.search.resolved;
          }

          return variables;
        },
        skip () {
          return !this.model;
        },
        fetchPolicy: 'no-cache',
        result (res, key) {
          this.loading = res.loading;
        },
        update: function (data) {
          this.defectCount = data.searchDefects.totalCount;
          this.stations = data.getStations;
          this.defects = data.searchDefects.edges.map(edge => edge.node);

          return data;
        },
      },
    },

    data: function () {
      return {
        defects: [],
        defectCount: 0,
        stations: [],
        loading: true,
        pagination: {
          itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
          first: DEFAULT_ITEMS_PER_PAGE,
          last: null,
          before: null,
          after: null,
        },
        search: {
          type: null,
          parameter: null,
          station: null,
          seriousness: null,
          from: null,
          to: null,
        },
        validation: require('@validation/search/defect.json'),
        headers: [
          {
            text: this.$t('labels.defect.seriousness'),
            value: 'seriousness',
            align: 'center',
          },
          {
            text: this.$t('labels.defect.type'),
            value: 'type',
            align: 'center',
          },
          {
            text: this.$t('labels.defect.description'),
            value: 'description',
            align: 'left',
          },
          {
            text: this.$t('labels.defect.at'),
            value: 'at',
            align: 'center',
          },
          {
            text: this.$t('labels.defect.until'),
            value: 'until',
            align: 'center',
          },
          {
            text: this.$t('messages.views.admin.components.core.defectsModal.station'),
            value: 'station',
            align: 'center',
          },
          {
            text: this.$t('messages.views.admin.components.core.defectsModal.resolved'),
            value: 'resolved',
            align: 'center',
          },
        ],
      };
    },

    computed: {
      ...mapState('defect', ['defectId']),
      model: {
        get () { return this.value; },
        set (model) {
          if (!model && this.defectId !== null) {
            this.$store.commit('defect/hideDefect');
          }

          if (this.defectId === null) {
            this.resetPagination();
          }

          this.$emit('input', model);
        },
      },
      pageCount () {
        return Math.ceil(this.defectCount / this.pagination.itemsPerPage) + 1;
      },
      types () {
        if (this.search.seriousness !== null) {
          return getTypes(this.search.seriousness);
        } else {
          return getTypes(false);
        }
      },
      searchParameterLabel () {
        if (this.search.type === null) {
          return this.$t('labels.defect.parameter');
        } else {
          return getDefectParameterLabelFromType(parseInt(this.search.type));
        }
      },
      isSearchParameterDisabled () {
        if (this.search.type === null) {
          return false;
        } else {
          return getDefectParameterType(parseInt(this.search.type)) === null;
        }
      },
    },

    watch: {
      defectId: {
        immediate: true,
        handler (newValue) {
          this.model = newValue !== null;
        },
      },
      pagination: {
        handler () {
          this.loading = true;
          this.$apollo.queries.data.refresh();
        },
        deep: true,
      },
      search: {
        handler () {
          this.loading = true;
          this.resetPagination();
        },
        deep: true,
      },
      'search.seriousness' () {
        if (this.search.type !== null && !this.types.includes(this.search.type)) {
          this.search.type = null;
        }
      },
    },

    methods: {
      resetPagination () {
        this.pagination.first = DEFAULT_ITEMS_PER_PAGE;
        this.pagination.after = null;
        this.pagination.last = null;
        this.pagination.before = null;
      },

      previous () {
        this.pagination.first = null;
        this.pagination.after = null;
        this.pagination.last = this.pagination.itemsPerPage;
        this.pagination.before = this.data.searchDefects.edges[0].cursor;
      },

      next () {
        this.pagination.first = this.pagination.itemsPerPage;
        this.pagination.after = this.data.searchDefects.edges[this.data.searchDefects.edges.length - 1].cursor;
        this.pagination.last = null;
        this.pagination.before = null;
      },

      getSeriousness (defect) {
        return getSeriousness(defect.type);
      },

      getSeriousnessColor (seriousness) {
        switch (parseInt(seriousness)) {
          case 2:
            return 'error';
          case 1:
            return 'warning';
          case 0:
            return 'info';
        }
      },

      getDefectDescription (defect) {
        return getDefectDescription(defect.type);
      },

      getDefectParameterLabel (defect) {
        return getDefectParameterLabelFromType(defect.type);
      },

      getItemClass (item) {
        const classes = [];
        if (item.until === null) {
          classes.push('font-weight-bold');
        }

        if (this.defectId && this.defectId === parseInt(item.id)) {
          classes.push('secondary white--text');
        }

        return classes.concat(' ');
      },

      getStationName (station) {
        return getStationName(station);
      },
    },
  };
</script>
